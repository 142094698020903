<template>
  <div style="padding:0px 10px;">
    <van-list v-model="loading" :finished="finished" finished-text="已经到底了" @load="onPageChange" class="orderList">
      <van-row class="item" v-for="(item,index) in dataList" :key="item.id">
        <van-col span="24" class="ordernumber">订单编号：{{item.ordernumber}}<span :class="'status status'+item.status">{{item.status==2?'待核销':'已完成'}}</span></van-col>
        <van-col span="24">
          <van-divider :style="{height:'10px',margin:'0px'}"/>
        </van-col>
        <van-col span="24" class="tips" v-if="item.goodInfo.remarks != '' && item.goodInfo.remarks != null">{{item.goodInfo.remarks}}</van-col>
        <van-col span="24" class="ginfo">
          <van-row type="flex" :gutter="10">
            <van-col span="6"><img :src="item.goodInfo.titleimg" class="img"/></van-col>
            <van-col span="18">
              <p class="title">{{item.goodInfo.title}}</p>
              <p class="price"><span>¥<em>{{item.uprice}}</em>元</span></p>
              <p class="stock">数量：{{item.num}}</p>
            </van-col>
          </van-row>
        </van-col>
        <van-col span="24">
          <van-divider :style="{height:'20px',margin:'0px'}"/>
        </van-col>
        <van-col span="24">
          <van-row type="flex" justify="space-between" align="center">
            <van-col class="total_price">合计:{{item.money}}元</van-col>
            <van-col class="action">
              <van-button plain type="info" size="small" @click="orderDetail(item)">订单详情</van-button>
              <van-button plain type="info" size="small" v-if="item.status==2" @click="hx(item,index)">立即核销</van-button>
            </van-col>
          </van-row>
        </van-col>
      </van-row>
    </van-list>
  
    <van-popup v-model="hxModal" position="bottom" class="ExchangePopup">
      <van-form @submit="onSubmitHx">
        
        <van-field v-model="json.hxpassword" label="核销码" placeholder="请输入核销码" input-align="right" :rules="[{ required: true,message: '请输入核销码'}]" :error="false" error-message-align="right"/>
        <div class="font-color-red mt10">请店员在此输入核销密码</div>
        <div class="mt10"><van-button color="linear-gradient(to right, #ff6034, #ee0a24)" size="small" block round native-type="submit">立即核销</van-button></div>
      </van-form>
    </van-popup>
  </div>
</template>

<script>
  import {GetorderList, GoodExchange, OrderHx} from "./services/services";
  import {mapGetters} from "vuex";
  export default {
    name:'order',
    components: {},
    data() {
      return {
        hdid:undefined,
        dataList:[],
        pagination:{
          pageIndex:1,
          pageSize:10,
          pageCount:0,
          total:0
        },
        currentIndex:undefined,
        json:{
          hxpassword:''
        },
        loading:false,
        finished:false,
        hxModal:false
      }
    },
    computed:{
      ...mapGetters(['userInfo']),
    },
    created(){
      this.hdid=parseInt(this.$route.params.hdid)
    },
    mounted() {
      this.findList()
    },
    methods: {
      findList(star){
        this.loading = true
        let params = {
          searchbean:{
            hdid:this.hdid
          },
          pageIndex:this.pagination.pageIndex,
          pageSize:this.pagination.pageSize
        }
        star=star==undefined?true:star
        GetorderList(params).then(result => {
          this.loading=false
          if(result.data.code==1){
            const {list,recordCount,pageCount} = result.data.data
            this.dataList = star?list:[...this.dataList,...list]
            this.pagination.total = recordCount
            this.pagination.pageCount=pageCount
            if(this.dataList.length>=recordCount){
              this.finished = true
            }
          }else{
            this.$toast(result.data.msg)
          }
        })
      },
      orderDetail(record){
        this.$router.push({path:'/douyin/order/detail/'+record.id})
      },
      hx(record,index){
        const {id,hdid,pid,uid} = record
        this.json={
          ...this.json,
          id,hdid,pid,uid
        }
        this.currentIndex=index
        this.hxModal=true
      },
      onSubmitHx(){
        this.$toast.loading({message: '正在核销中...',forbidClick: true})
        OrderHx(this.json).then(result => {
          if(result.data.code==1){
            this.hxModal=false
            this.dataList[this.currentIndex].status=3
            this.json={
              hxpassword:''
            }
            this.$toast.success(result.data.msg)
          }else{
            this.$toast(result.data.msg)
          }
        })
      },
      onPageChange() {
        if (this.pagination.pageIndex >= this.pagination.pageCount) {
          return false
        }
        this.pagination.pageIndex += 1
        this.findList(false)
      },
    }
  }
</script>
<style lang="less" scoped>
  @import "@/assets/css/order.less";
</style>
